.header {
  color: $color-lightblack;
  box-shadow: 0 0 22px 5px #00000030;
  font-family: $font-family--robotomono;

  @media (max-width: 1023px) {
    height: 90px;
  }

  &--main {
    position: relative;
    box-shadow: none;
    color: #ffffff;
    background-color: transparent;
    z-index: 20;
    &.header .dots svg * {
      fill: #fff;
    }
    &.header .header__login_social svg * {
      fill: #fff;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000000, 0.5);
    }

    .header__wrapper {
      @media (max-width: 1023px) {
        margin: auto;
        height: 90px;
      }
    }

    .header__btn-menu {
      z-index: 11;
      svg {
        color: #ffffff;
      }

      &::before,
      &::after {
        background-color: #ffffff;
      }
    }

    .header__btn-menu--active {
      
      &::before,
      &::after {
        background-color: #000000;
      }
    }

    a {
      color: inherit;
    }

    .header__page-item--icon::after {
      border-color: #ffffff;

      // @media (max-width: 1023px) {
      //   border-color: #000000;
      // }
    }
  }

  .dots svg * {
    fill: #000;
  }
  .header__login_social svg * {
    fill: #3e6bff;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__logo-image-wrap {
    display: flex;
    align-items: center;
    max-width: 230px;

    .header__logo-main {
      display: none;
    }

    &--main {
      .header__logo-inner-page {
        display: none;
        -o-object-fit: cover;
           object-fit: cover;
      }

      .header__logo-main {
        display: block;
        width: 100%;
        min-width: 230px;
        height: auto;

        @media (max-width: 1200px) {
          min-width: 210px;
        }

        @media (max-width: 1023px) {
          min-width: 180px;
        }
      }
    }
  }

  &__logo {
    display: flex;
    width: percentage(300/1410);

    @media (max-width: 1023px) {
      width: 60%;
    }

    @media (max-width: 1023px) {
      width: 180px;
    }
  }

  &__icon-logo {
    width: 290px;
    height: 80px;
    color: #000000;
    align-self: baseline;
    padding-top: 10px;

    // &--main {
    //   color: #ffffff;
    // }

    @media (max-width: 1200px) {
      width: 220px;
    }

    @media (max-width: 1023px) {
      width: 180px;
    }

    @media (max-width: 1023px) {
      padding-top: 10px;
    }

    @media (max-width: 767px) {
      width: 180px;
    }

    @media (max-width: 360px) {
      width: 150px;
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    text-transform: uppercase;
    transition: opacity 0.3s $default-transition-settings;

    svg {
      margin-right: 15px;

      @media (max-width: 1180px) {
        margin-right: 10px;
      }
    }

    span {
      @media (max-width: 440px) {
        display: none;
      }
    }

  }

  .header__page-item--icon_dots.header__page-item--active .dots {
    .header__btn-menu--active::after {
      svg {
        background-color: #000;
      }
    }
  }

  .exit {
    display: none;
  }

  .header__page-item--active {
    .exit {
      display: none;
      @media (max-width: 768px) {
        display: block;
        position: fixed;
        top: 5%;
        right: 5%;
        background: transparent;
        border: none;
        z-index: 11;
        font-size: 40px;
        font-weight: 100;
        opacity: .8;
      }
    }
  }
  &__navigation {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    @media (max-width: 576px) {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }
    &_wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      @media (max-width: 576px) {
        display: none;
      }
    }
  }
  .header__page-item-social {
    display: flex;
    @media (max-width: 576px) {
      opacity: 0;
    }
  }
  .header__navigation_wrapper {
    @media (max-width: 576px) {
      display: none;
    }
  }
  .hidden-links {
    .header__sublist {
      position: static;
      a {
        font-weight: normal;
        line-height: 1.1;
        display: block;
        margin-top: 10px;
      }
    }
    .header__page-item {
      & > span, & > a {
        font-family: RobotoMono;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        color: #2c2c2c;
        text-transform: uppercase;
      }
      &-login {
        display: none;
      }
      &--icon {
        & > .header__item-name {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            display: block;
            padding-right: 6px;
            padding-bottom: 6px;
            border-right: 2px solid #000;
            border-bottom: 2px solid #000;
            transform: rotate(45deg);
            transition: opacity .3s .3s ease;
            right: -15px;
            top: 3px;
          }
        }
      }
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #000;
      padding: 10px 0;
      display: block;
      &.header__page-item-social {
        display: none;
        @media (max-width: 576px) {
          display: flex;
          opacity: 1;
          margin-top: 50px;
        }
        .header__login_social {
          margin-right: 10px;
          svg * {
            fill:#000;
          }
        }
      }
      & > .header__sublist {
        display: none;
      }
      &--active {
        & .header__item-name {
          &::after {
            transform: translateY(5px) rotate(-135deg)
          }
        }
        & > .header__sublist {
          display: block;
        }

      }
      svg * {
        fill: black;
      }
      span {
        color: black;
      }
    }
    .header__login_social {
      display: flex;
    }
    @media (max-width: 576px) {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      margin: 0;
      background-color:#fff;
    }
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }

  &__pages-list {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.88;

    @media (max-width: 1023px) {
      &--mobile {
        display: flex;
        .header__page-item--icon.header__page-item--icon_dots {
          display: none;
        }
        .invisible-three, .invisible-two, .invisible-one {
          display: block;
        }
      }

      .header__login_social {
        svg * {
          fill: #000000;
        }
      }
    }

    &--mobile {
      display: block;
      z-index: 10;
      background-color: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      min-height: 100vh;

      @media (max-width: 1023px) {
        left: -25px;
      }

      @media (max-width: 767px) {
        left: -16px;
      }

      .header__page-item {
        padding-left: 30px;
        width: 80%;
      }

      .header__page-item--icon {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__page-item {
    display: flex;
    padding: 30px 12px;
    position: relative;
    cursor: pointer;
    &--phone {
      min-width: 205px;
    }

    &:hover {
      .header__item-name,
      .header__login,
      .header__phone,
      .header__item-link {
        opacity: 0.7;
      }
      .header__login_social {
        opacity: 1;
      }
    }

    .header__login_social:hover {
      opacity: 0.7;
    }

    &--icon {
      padding-right: 25px;

      &::after {
        content: "";
        position: absolute;
        top: 39px;
        right: 5px;
        display: block;
        padding-right: 6px;
        padding-bottom: 6px;
        border-right: 2px solid #000000;
        border-bottom: 2px solid #000000;
        transform: rotate(45deg);
        transition: opacity 0.3s $default-transition-settings;

        @media (max-width: 576px) {
          display: none;
        }

        // @media (max-width: 1180px) {
        //   top: 42px;
        //   right: -2px;
        //   padding-right: 3px;
        //   padding-bottom: 3px;
        // }

        // @media (max-width: 1023px) {
        //   border-color: #000000;
        //   right: auto;
        //   top: 40px;
        //   left: 120px;
        //   transform: translateY(5px) rotate(45deg);
        // }
      }
      &_dots {
        position: relative;
        list-style-type: none;
        // display: none;
        .hidden-links {
          position: absolute;
          left: -190px;
          .header__page-item--phone {
            display: none;
          }
          .header__page-item--icon::after {
            display: none;
          }
          @media (max-width: 576px) {
            position: fixed;
            top: 0;
            left: 0;
            padding-top: 40px;
            height: 100vh;
            width: 100vw;
            margin: 0;
            background-color: #fff;
            .header__subitem {
              font-size: 16px;
              color: #000;
            }
          }
          @media (max-width: 576px) {
            padding-top: 70px;
          }
          .header__item-link {
            color: black;
          }
        }
        @media (max-width: 576px) {
          
          .header__item-name {
            svg {
              display: none;
            }
          }
          .hamburger {
            display: block;
          }
        }
        &::after {
          display: none;
        }
        @media (max-width: 1280px) {
          display: block;
        }
        @media (max-width: 576px) {
          display: flex;
        }
      }
      &:hover::after {
        opacity: 0.7;
      }
    }

    &--active {
      &::after {
        transform: translateY(5px) rotate(-135deg);
      }

      .header__sublist {
        display: block;
        z-index: 10;
      }

    }

    &--phone {
      @media (max-width: 576px) {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    // @media (max-width: 1355px) {
    //   padding: 30px 15px;
    // }
  }

  & a.header__item-link {
    display: inline-block;
    font-size: 16px;
    transition: opacity 0.3s $default-transition-settings;

    @media (max-width: 1355px) {
      // font-size: 1.02vw;
      // font-size: 14px;
    }

    @media (max-width: 1180px) {
      // font-size: 12px;
    }

    @media (max-width: 1023px) {
      // font-size: 14px;
      // color: #000000;
    }

  }

  &__item-name {
    display: inline-block;
    transition: opacity $default-transition-settings;

    @media (max-width: 1023px) {
      // color: #fff;
      font-size: 16px;
    }
  }

  &__sublist {
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
    list-style: none;
    background-color: #ffffff;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 200px;
    padding: 10px 20px;
    transition: opacity 0.3s $default-transition-settings;

    @media (max-width: 1023px) {
      width: auto;
      padding: 0;
      padding-left: 20px;
      padding-top: 20px;
    }
    @media (max-width: 576px) {
      padding-top: 10px;
    }
  }

  &__subitem {
    color: $color-lightblack;
    transition: opacity 0.3s $default-transition-settings;

    @media (max-width: 1023px) {
      font-size: 16px;
      font-weight: 400;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__login {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    transition: opacity 0.3s $default-transition-settings;

    svg {
      margin-right: 15px;

      @media (max-width: 1180px) {
        margin-right: 10px;
      }
    }
    &_social {
      svg {
        margin-right: 5px;
      }
    }
  }

  &__btn-menu-wrapper {
    display: none;

    @media (max-width: 576px) {
      display: block;
    }
  }

  &__btn-menu {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    outline: none;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 18px;
      height: 2px;
      bottom: 8px;
      left: 0;
      transform: scale(0);
      background-color: $color-lightblack;
      transition:
        transform 0.5s ease,
        opacity 0.5s ease,
        background-color 0.5s ease,
        color 0.5s ease;
      opacity: 0;
    }

    svg {
      width: 18px;
      height: 12px;
      fill: none;
      transition:
        transform $default-transition-settings,
        opacity $default-transition-settings,
        color $default-transition-settings;
      opacity: 1;
    }

    &:hover {
      opacity: 0.8;

      &::before,
      &::after {
        opacity: 0.8;
      }
    }

    &--active {
      z-index: 20;
      position: fixed;
      right: 5%;
      top: 35px;

      @media (max-width: 1023px) {
        right: 3%;
      }

      @media (max-width: 767px) {
        right: 4%;
      }

      &::before,
      &::after {
        background-color: $color-lightblack;
      }

      svg {
        transform: scale(0);
        opacity: 0;
        color: #000000;
      }

      // &::before {
      //   opacity: 1;
      //   transform: scale(1) rotate(45deg);
      // }

      // &::after {
      //   opacity: 1;
      //   transform: scale(1) rotate(-45deg);
      // }
    }
  }
}
