.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba($color-default-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include viewport--sm {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;

    background-color: $color-default-white;

    @include viewport--sm {
      width: 100%;
      padding: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--no-scale {
    transform: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
}
