.page-about {
  padding-bottom: 50px;
}

.about {
  background-color: #f1f1f1;
  margin-bottom: 50px;
}

.about__list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
  width: 1100px;

  @media (max-width: 1100px) {
    width: 980px;
  }
}

.about__item {
  padding-top: 30px;
  padding-bottom: 30px;
}

.about__link {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #3e6bff;
  transition: 0.3s ease;

  &--active {
    @include fontface(Montserrat, Montserrat, Montserrat-SemiBold, 600, normal);
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #2c2c2c;
  }

  &:hover,
  &:focus {
    color: #2c2c2c;
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

@media (max-width: $tablet-width) {
  .about {
    overflow-x: scroll;
  }

  .about__list {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about__item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
