.link {
  &--back {
    position: absolute;
    background-image: url("../img/svg/arrow_back.svg");
    background-repeat: no-repeat;
    width: 52px;
    height: 52px;
    transition: 0.3s ease;

    &:hover,
    &:focus {
      outline: none;
      opacity: 0.8;
      transition: 0.3s ease;
    }

    &:active {
      opacity: 0.6;
      transition: 0.3s ease;
    }
  }

  &--newsitem {
    background-image: url("../img/svg/arrow_back--blue.svg");
  }
}

