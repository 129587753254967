.activities__list {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2c2c2c;
  padding: 0;
  margin: 0;
  padding-bottom: 0;
  counter-reset: myCounter;
}

.activities__item {
  padding-bottom: 15px;
  list-style: none;
  padding-left: 59px;
  position: relative;

  &::before {
    position: absolute;
    counter-increment: myCounter;
    content: counter(myCounter);
    color: white;
    background: #000000;
    display: inline-block;
    text-align: center;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    top: 2px;
    left: 14px;
  }
}
