.text {
  padding-top: 63px;
  width: 810px;
  margin: 0 auto;
}

.text h2 {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #2c2c2c;
  padding: 0;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 46px;
  width: 690px;
}

.text p {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2c2c2c;
  padding: 0;
  margin: 0 auto;
  padding-bottom: 14px;
  width: 690px;

  &:last-child {
    padding-bottom: 46px;
  }
}

.text__image {
  width: 750px;
  height: 508px;
}

.text figure {
  padding: 0;
  margin: 0 auto;
  width: 750px;
  padding-top: 45px;
}

.text figcaption {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
  padding-top: 38px;
  padding-bottom: 62px;
  padding-right: 20px;
}

.text__wrapper--advertising {
  padding-top: 60px;
  color: #2c2c2c;
  width: 690px;
  margin: 0 auto;
}

.text h3 {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  padding: 0;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 60px;
  width: 690px;
}

.text ul {
  padding: 0;
  margin: 0 auto;
  width: 690px;
  padding-bottom: 45px;
  list-style: none;
}

.text blockquote {
  width: 750px;
  background-color: #304ffe;
  display: flex;
  margin: 0 auto;
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 33px;
  padding-left: 119px;
  padding-right: 45px;
  position: relative;
}

.text svg {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 43px;
  top: 30px;
}

.text li {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  padding-bottom: 15px;
  padding-left: 59px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: #000000;
    display: inline-block;
    text-align: center;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    top: 0;
    left: 15px;
  }

  &::after {
    content: "";
    position: absolute;
    background-image: url("../img/svg/check.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    top: 0;
    left: 15px;
  }
}

.text table {
  width: 750px;
  margin: 0 auto;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  border-collapse: collapse;
  text-align: left;
  margin-bottom: 181px;
}

.text table th {
  background: #304ffe;
  color: white;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;

  &:first-child,
  &:nth-child(2) {
    width: 240px;
  }
}

.text table td {
  border-style: solid;
  border-width: 0 0 0 0;
  border-color: transparent;
  padding-top: 31px;
  padding-bottom: 9px;
  padding-left: 30px;
  padding-right: 30px;

  &:first-child {
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #2c2c2c;
  }
}

.text table tr:nth-child(odd) td:first-child {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  padding-top: 9px;
  padding-bottom: 33px;
}

.text table tr:nth-child(4n),
.text table tr:nth-child(4n+1) {
  background-color: #f1f1f1;
}

@media (max-width: $tablet-width) {
  .text {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .text blockquote {
    width: 100%;
    max-width: 750px;
  }

  .text table {
    width: 100%;
    max-width: 790px;
  }

  .text figure {
    width: 100%;
    margin: 0 auto;
    max-width: 750px;
  }
}

@media (max-width: $mobile-width) {
  .text {
    padding-top: 30px;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    max-width: 690px;
  }

  .text p,
  .text h2,
  .text h3,
  .text ul {
    width: 100%;
  }

  .text h2 {
    font-size: 20px;
  }

  .text p {
    font-size: 14px;
  }

  .text h3 {
    font-size: 16px;
  }

  .text li {
    font-size: 14px;
  }

  .text figure {
    width: 100%;
  }

  .text img {
    width: 100%;
  }

  .text blockquote {
    font-size: 16px;
    padding-left: 55px;
    padding-right: 15px;
  }

  .text svg {
    width: 30px;
    height: 30px;
    left: 15px;
    top: 30px;
  }

  .text table {
    width: 100%;
    padding-bottom: 100px;
    font-size: 14px;
    margin-bottom: 45px;
  }

  .text table th {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 9px;
    padding-right: 8px;

    &:first-child,
    &:nth-child(2) {
      width: 35%;
    }
  }

  .text table td {
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      font-size: 14px;
    }
  }

  .text table tr:nth-child(odd) td:first-child {
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 12px;
  }
}
