.last-news {
  margin: 0 auto;
  margin-top: 89.5px;
  background-color: #f1f1f1;
  max-width: 990px;
  min-height: 510px;
  margin-bottom: 60px;
}

.last-news__list {
  max-width: 990px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.last-news__link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  cursor: pointer;
}

.last-news__image {
  width: 48.4%;
}

.last-news__item img {
  width: 100%;
  height: auto;
}

.last-news__wrapper {
  width: 48.4%;
  display: flex;
  flex-direction: column;
}

.last-news__wrapper time {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  padding-top: 30px;
  padding-left: 9px;
}

.last-news__wrapper p {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2c2c2c;
  padding: 0;
  margin: 0;
  padding-top: 31px;

  &:first-of-type {
    padding-left: 8px;
    font-size: 14px;
  }
}

.last-news__wrapper h2 {
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #2c2c2c;
  padding: 0;
  margin: 0;
  padding-top: 5px;
}

.last-news__wrap {
  display: flex;
  flex-direction: row;
  padding-top: 29px;
}

.last-news__wrapper span {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: center;
  color: #3e6bff;
  padding-right: 10px;
}

@media (max-width: $tablet-width) {
  .last-news {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .last-news__wrapper time {
    padding-top: 0;
    padding-left: 9px;
  }

  .last-news__wrapper h2 {
    font-size: 22px;
    padding-top: 8px;
  }

  .last-news__wrapper p {
    font-size: 14px;
    padding-top: 15px;
  }

  .last-news__wrap {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
  }
}

@media (max-width: $mobile-width) {
  .last-news__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .last-news__image {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }

  .last-news__item img {
    width: 100%;
    height: auto;
  }

  .last-news__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .last-news__wrapper time {
    font-size: 14px;
    padding-top: 30px;
    padding-left: 9px;
  }

  .last-news__wrapper p {
    font-size: 14px;
    padding-top: 31px;
  }

  .last-news__wrapper h2 {
    font-size: 20px;
    padding-top: 8px;
  }

  .last-news__wrap {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
  }

  .last-news__wrapper span {
    font-size: 14px;
    padding-right: 10px;
  }
}
