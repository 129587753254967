// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/swiper";

// Global
//---------------------------------

@import "variables";
@import "mixins";
@import "global/fonts";
@import "global/typography";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks

@import "blocks/description-block";
@import "blocks/accordion";
@import "blocks/header";
@import "blocks/header-develop";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/navigation";
@import "blocks/link";
@import "blocks/about";
@import "blocks/activities";
@import "blocks/structure";
@import "blocks/text";
@import "blocks/meeting";
@import "blocks/form";
@import "blocks/conditions";
@import "blocks/btn-wrap";
@import "blocks/block-grant";
@import "blocks/main-slider";
@import "blocks/btn";
@import "blocks/btn-link";
@import "blocks/news";
@import "blocks/last-news";
@import "blocks/news-slider";
@import "blocks/other-news";
@import "blocks/newsitem";
@import "blocks/newsitem-slider";
@import "blocks/main-title";
@import "blocks/main";
@import "blocks/main-persons";
@import "blocks/main-investors";
@import "blocks/main-news";
@import "blocks/main-about";
@import "blocks/main-activity";
@import "blocks/main-winners";
@import "blocks/main-grants";
@import "blocks/competition";
@import "blocks/breadcrumbs";
@import "blocks/main-subscribe";
@import "blocks/main-partners";
@import "blocks/portfolio";
// @import "blocks/footer-wrapper";

// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
