.main-persons {
  font-family: $font-family--robotomono;
  position: relative;
  padding: 43px 0 26px;
  background-image: linear-gradient(173deg, #304ffe 5%, #283593 96%);

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 1061px;
    background-image: url("../img/bg/bg-wave-cart-2@1x.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 410px;
    background-image: url("../img/bg/bg-wave-cart-1@1x.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    transform: translateX(-50%);
  }

  &__head-wrap {
    position: relative;
    z-index: 1;
  }

  &__head {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 90px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    padding-bottom: 44px;
  }

  &__head-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: percentage(690/1410);
    // margin-left: percentage(210/1410);
    padding-top: 72px;

    @media (max-width: 1023px) {
      width: 100%;
      max-width: 780px;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__head-title {
    padding: 0;
    margin: 0;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 700;
    margin-bottom: 30px;

    @media (max-width: 1023px) {
      max-width: 90%;
      margin: 0 auto 30px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 1.45;
    }
  }

  &__icon-quotes {
    position: absolute;
    top: 65px;
    left: -70px;
    width: 40px;
    height: 40px;

    @media (max-width: 1023px) {
      left: -13px;
      width: 20px;
      display: block;
    }

    @media (max-width: 767px) {
      left: 0;
      margin-right: 10px;
      // display: none;
    }
  }

  &__image-wrap {
    height: 330px;
    margin-right: 210px;
    margin-top: 35px;
    margin-right: 15%;
    flex-shrink: 0;
    margin-left: 4%;

    @media (max-width: 1023px) {
      margin: 0 auto;
      margin-bottom: 50px;
    }

    @media (max-width: 450px) {
      width: 250px;
      height: 250px;
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__name {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    margin-bottom: 10px;
    padding: 0;
    margin: 0;

    // &--item {
    //   // margin-bottom: 25px;
    // }
  }

  &__position {
    font-family: $font-family--monsterrat;
    font-size: 14px;
    line-height: 1.71;
    padding: 0;
    margin: 0;
  }

  &__list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 percentage(50/1410);

    @media (max-width: 1023px) {
      padding: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: percentage(330/1410);
    flex-shrink: 1;
    margin-right: percentage(210/1410);
    text-align: center;
    margin-bottom: 86px;

    &:nth-child(3n) {
      margin-right: 0;

      @media (max-width: 1023px) {
        margin-right: percentage(210/1410);
      }

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @media (max-width: 1023px) {
        margin-right: 0;
      }
    }

    @media (max-width: 1023px) {
      width: 40%;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__bg-img {
    display: flex;
    width: 212px;
    height: 212px;
    border-radius: 50%;
    background: linear-gradient(140deg, #c2185b, #304ffe 70%);
    margin-bottom: 30px;
  }

  &__item-image {
    border-radius: 50%;
    border-image-slice: 1;
    height: 180px;
    //opacity: 0.4;
    margin: auto;
  }
}
