.news {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0;
  margin: 0;
  list-style: none;
}

.news li {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
  margin-right: 30px;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 22.5px;
  background-color: #304ffe;
  transition: 0.3s ease;

  &:first-child {
    margin-left: 49px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
    cursor: pointer;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

.news a {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
}

.news li.selected-news-tag {
  background-color: #ffffff;
  color: $color-lightblue;
}

.news-tags-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__btn:hover {
    color: #304ffe;
    background-color: #fff;
  }

  &__btn {
    flex-basis: 100%;
    padding: 0 20px;

    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 14px;
    line-height: 2.14;
    color: #fff;

    background-color: #304ffe;
  }

  &__btn:first-child {
    margin-right: 1px;
    border-radius: 22px 0 0 22px;
  }

  &__btn:last-child {
    margin-left: 1px;
    border-radius: 0 22px 22px 0;
  }
}


@media (max-width: $mobile-width) {
  .news {
    font-size: 16px;
    margin: 0 auto;
    margin-top: 0;
    white-space: nowrap;
  }

  .news li {
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
    margin-right: 30px;
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 22.5px;
    background-color: #304ffe;

    &:first-child {
      margin-left: 49px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .news a {
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
  }
}
