.form {
  width: 330px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.form label {
  font-size: 0;
}

.form input {
  width: 330px;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  background: #ffffff;
  border-radius: 30.5px;
  border: solid 1px #e6e6e6;
  resize: none;
  margin-bottom: 30px;
  padding-left: 23px;
  padding-top: 10px;
  padding-bottom: 11px;
  outline: none;
  transition: 0.3s ease;

  &::placeholder {
    color: #000000;
  }

  &:focus {
    border: 1px solid #304ffe;
    transition: 0.3s ease;
  }

  &:hover {
    opacity: 0.8;
    transition: 0.3s ease;
  }
}

.form button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  align-self: flex-start;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #304ffe;
  border-radius: 30.5px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    color: #304ffe;
    background-color: #ffffff;
    transition: 0.3s ease;
    outline: none;
  }

  &:active {
    opacity: 0.8;
  }
}

@media (max-width: $tablet-width) {
  .form {
    width: 44.2%;
    max-width: 786px;
  }

  .form input {
    width: 100%;
  }
}

@media (max-width: $mobile-width) {
  .form {
    width: 100%;
    max-width: 330px;
    padding-bottom: 30px;
    margin-right: 0;
  }
}
