.main-title {
  position: relative;
  max-width: 810px;
  font-family: $font-family--robotomono;
  font-size: 36px;
  line-height: 1.25;
  font-weight: 700;
  padding-bottom: 38px;
  margin: 0;
  margin-bottom: 41px;

  @media (max-width: 767px) {
    font-size: 30px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 76px;
    height: 8px;
    background-color: #f1f1f1;
  }

  &--color {
    color: $color-lightblack;

    &::before {
      background-color: $color-lightblue;
    }
  }
}
