.main {
  color: #ffffff;

  &__banner {
    background-color: $color-gray2;
    text-align: center;
    padding: 51px 0 54px;
    margin-bottom: 60px;
  }

  &__banner-text {
    margin: 0;
    font-family: $font-family--robotomono;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.25;
    color: $color-lightblack;
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }
}
