.main-news {
  padding-top: 53px;
  padding-bottom: 42px;

  @media (max-width: 1200px) {
    padding-bottom: 50px;
  }

  &__head {
    display: flex;
    // margin-bottom: 40px;
    &__wrapper {
      display: flex;
      justify-content: space-between;
      width: 50%;
      padding-right: 20px;
      @media (max-width: 1360px) {
        .main-news__title {
          font-size: 30px;
          margin-right: 20px;
        }
      }
      @media (max-width: 992px) {
        display: block;
      }
      @media (max-width: 767px) {
        width: 45%;
        padding-right: 0;
        &:nth-child(2n) {
          text-align: right;
          .main-news__title {
            &::before {
              right: 0;
              left: auto;
            }
          }
        }
      }
      @media (max-width: 576px) {
        width: 50%;
        padding-right: 0;
      }
    }
    &__jcsb {
      justify-content: space-between;
      width: percentage(960/1410);
      @media (max-width: 1200px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        flex-direction: row;
      }
      @media (max-width: 576px) {
        .main-news__title {
          font-size: 24px;
          margin-right: 0;
        }
      }
    }

    // @media (max-width: 767px) {
    //   flex-direction: column;
    // }
  }

  &__title {
    margin-right: 30px;
    margin-bottom: 13px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__head-link {
    align-self: baseline;
    margin-top: 10px;
  }

  &__content {
    display: flex;
    width: 100%;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }

  &__list-wrap {
    position: relative;
    width: percentage(960/1410);

    @media (max-width: 1200px) {
      padding-top: 0;
      width: 100%;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    padding-top: 30px;
    margin: 0;
    list-style: none;
  }

  &__item {
    width: 49%;
    margin-bottom: 57px;

    &:last-child {
      margin-bottom: 51px;
    }

    &:nth-last-child(2) {
      margin-bottom: 51px;
    }
    &:hover .main-news__item-content {
      background-color: #f1f1f1;
    }

    @media (max-width: 767px) {
      width: 45%;
    }

    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__item-link {
    display: flex;
    padding: 0;
    background-color: transparent;


    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__item-img {
    width: percentage(210/450);
    // width: 210px;
    max-width: 210px;
    max-height: 210px;
    -o-object-fit: cover;
       object-fit: cover;

    @media (max-width: 1200px) {
      width: 50%;
      max-width: none;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__item-content {
    width: percentage(240/450);
    padding: 20px 0 0 30px;
    padding-left: 7%;
    transition: background-color $default-transition-settings;

    @media (max-width: 1300px) {
      padding-top: 0;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 15px 5px;
    }
  }

  &__date {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
  }

  &__item-info {
    min-height: 110px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.71;
    color: #000000;
    margin: 0;

    @media (max-width: 767px) {
      min-height: auto;
      margin-bottom: 30px;
    }
  }

  &__current-news {
    display: flex;
    flex-direction: column;
    width: percentage(450/1410);
    align-self: baseline;
    padding: 54px 10px 60px 30px;
    background-color: #f1f1f1;
    margin-left: 15px;

    @media (max-width: 1200px) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__current-title {
    font-family: $font-family--robotomono;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.25;
    color: #2c2c2c;
    margin: 0;
    margin-bottom: 54px;
  }

  &__subtitle {
    font-family: $font-family--robotomono;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    color: #354aa4;
    margin: 0;
    margin-bottom: 30px;
  }

  &__current-info {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.88;
    color: #2c2c2c;
    margin: 0;
    margin-bottom: 102px;
  }

  &__current-link {
    align-self: flex-start;
    padding: 9px 25px;

    svg {
      margin-left: 18px;
    }
  }

  &__show-more {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  &__btn-show-more {
    padding: 9px 25px;

    &--none {
      display: none;
    }
  }
}
