.newsitem-slider__btn-wrap {
  display: flex;
  align-items: center;
  width: max-content;
  margin-left: calc(50% + 163px);
  padding-bottom: 3px;
}

.newsitem-slider__btn {
  position: relative;
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  &:first-child {
    margin-right: 5px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    bottom: -10px;
    right: -10px;
  }
}

.newsitem-slider__toggles {
  display: flex;
  list-style: none;
  padding: 0;
  margin-right: 5px;
}

.newsitem-slider__toggle-item {
  width: 8px;
  height: 8px;
  border: solid 1px #2c2c2c;
  border-radius: 50%;
  margin-right: 7px;
  cursor: pointer;
  transition: background-color $default-transition-settings;

  &:last-child {
    margin-right: 0;
  }

  &--active {
    background-color: #2c2c2c;
  }
}

@media (max-width: $tablet-width) {
  .newsitem-slider__btn-wrap {
    margin: 0 auto;
    margin-top: -15px;
  }
}

@media (max-width: $mobile-width) {
  .newsitem-slider__btn-wrap {
    margin-top: -15px;
    // margin-left: auto;
  }
}
