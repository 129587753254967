.other-news--newsitem {
  padding-bottom: 77px;
}

.other-news__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 990px;
  padding: 0;
  margin: 0 auto;
  max-width: 990px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 45px;
}

.other-news__item {
  padding: 0;
  margin: 0;
  width: 450px;
  padding-bottom: 60px;
}

.other-news__link {
  padding: 0;
  margin: 0;
  width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
    background: #f1f1f1;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

.other-news__item img {
  width: 210px;
  height: 210px;
}

.other-news__wrapper {
  width: 210px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.other-news__wrapper p {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  padding-top: 18px;
  margin: 0;
}

.other-news__wrapper h3 {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  padding: 0;
  margin: 0;
  padding-top: 33px;
  padding-bottom: 40px;
}

.other-news__wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
}

.other-news__wrapper span {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: center;
  color: #3e6bff;
  padding-right: 10px;
}

.other-news__button {
  display: flex;
  align-items: center;
  max-width: 200px;
  margin: 0 auto;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
  background-color: $color-blue;
  padding-left: 32px;
  border: none;
  border-radius: 22.5px;
  padding-top: 7px;
  padding-bottom: 8px;
  padding-right: 8px;
  margin-bottom: 180px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

.other-news__button svg {
  margin-left: 4px;
  margin-right: 10px;
}

.other-news h2 {
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #2c2c2c;
  padding: 0;
  margin: 0 auto;
  width: 930px;
  padding-top: 88px;
  padding-bottom: 60px;
}

@media (max-width: $tablet-width) {
  .other-news__list {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
  }

  .other-news__link {
    width: 100%;
  }

  .other-news__item {
    width: 47.5%;
    padding-bottom: 45px;
  }

  .other-news__image {
    width: 45.5%;
  }

  .other-news__item img {
    width: 100%;
    height: auto;
  }

  .other-news__wrapper {
    width: 45.5%;
  }

  .other-news__wrapper time {
    font-size: 12px;
    padding-top: 0;
  }

  .other-news__wrapper h3 {
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 60px;
  }

  .other-news__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    bottom: 0;
  }

  .other-news__wrapper span {
    font-size: 12px;
  }

  .other-news__button {
    margin-bottom: 60px;
  }

  .other-news h2 {
    width: 100%;
  }
}

@media (max-width: $mobile-width) {
  .other-news__list {
    flex-direction: column;
    width: 100%;
    max-width: 990px;
  }

  .other-news__item {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }

  .other-news__button {
    font-size: 14px;
    padding-left: 32px;
    border: none;
    margin-bottom: 60px;
  }

  .other-news h2 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .other-news--newsitem {
    padding-bottom: 0;
  }
}
