.breadcrumbs {
  display: flex;
  border-radius: 22px;
  background-color: #fff;
  width: max-content;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 25px;

  &__link {
    font-family: $font-family--monsterrat;
    font-size: 14px;
    line-height: 2.14;
    color: #3e6bff;

    &::after {
      content: "/";
      padding: 0 8px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}