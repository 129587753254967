.btn-link {
  text-decoration: none;
  background-color: #f1f1f1;
  border-radius: 20px;
  color: #3e6bff;
  font-size: 14px;
  line-height: 1.8;
  padding: 3px 20px;
  transition: opacity $default-transition-settings;
  outline: none;

  &:hover {
    opacity: 0.6;
  }

  &--file {
    font-family: $font-family--monsterrat;
    font-size: 16px;
    color: #ffffff;
    background-color: transparent;
    padding: 5px 0;
  }

  &--border {
    padding: 2px 25px;
    padding-right: 15px;
    border: solid 1px $color-lightblue;
    border-radius: 22px;
    background-color: transparent;
  }
}
