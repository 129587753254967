.main-grants {
  padding-top: 80px;
  padding-bottom: 63px;
  color: #ffffff;

  &__head {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-right: 60px;
    padding-bottom: 54px;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }

  &__head-link {
    align-self: baseline;
    margin-top: 10px;

    padding: 3px 20px;
    font-size: 14px;
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__left-col {
    width: percentage(690/1410);

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
}
