@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first
@mixin viewport--xl {
  @media (max-width: $viewport--xxl - 1) {
    @content;
  }
}

@mixin viewport--lg {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

@mixin viewport--md {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

@mixin viewport--sm {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

@mixin fontface($family, $localname, $filename, $weight, $style) {
  @font-face {
    font-display: swap;
    font-family: $family;
    src:
      local("#{$localname}"),
      url("../fonts/#{$filename}.woff2") format("woff2"),
      url("../fonts/#{$filename}.woff") format("woff");
    font-weight: $weight;
    font-style: $style;
  }
}

// Mobile first

/* @mixin viewport--md {
  @media (min-width: $viewport--md) {
    @content;
  }
}

@mixin viewport--lg {
  @media (min-width: $viewport--lg) {
    @content;
  }
}

@mixin viewport--xl {
  @media (min-width: $viewport--xl) {
    @content;
  }
}

@mixin viewport--xxl {
  @media (min-width: $viewport--xxl) {
    @content;
  }
} */
