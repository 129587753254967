.btn {
  width: max-content;
  border: none;
  background-color: transparent;
  color: #ffffff;
  background-image: linear-gradient(76deg, #c2185b, #304ffe 65%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  padding: 8px 24px;
  cursor: pointer;
  transition: opacity $default-transition-settings;
  outline: none;


  @media (max-width: 767px) {
    font-size: 12px;
  }

  &--gradient {
    &:hover {
      opacity: 0.8;
      background-image: linear-gradient(76deg, #304ffe 45%, #c2185b);
    }
  }

  &--color-blue {
    background-image: none;
    background-color: $color-blue;

    &:hover {
      opacity: 0.7;
    }
  }

  &--transperent {
    background-image: none;
    background-color: transparent;
    border: 1px solid $color-lightblue;
    color: $color-lightblue;
    font-weight: 300;
    padding: 5px 35px;
    border-radius: 22.5px;

    &:hover {
      opacity: 0.6;
    }
  }

  &--color-white {
    background-image: none;
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;

    &:hover {
      opacity: 0.7;
    }
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }
}
