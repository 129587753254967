.main-slider {
  font-family: $font-family--robotomono;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // padding: 200px 0 28px;
  color: #ffffff;
  margin-top: -90px;
  position: relative;

  // @media (max-width: 767px) {
  //   padding: 150px 0 15px;
  // }

  // &__wrapper {
  //   padding-bottom: 89px;

  //   @media (max-width: 767px) {
  //     padding-bottom: 10px;
  //   }
  // }

  &__slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: center;
    padding: 200px 0 28px;
    min-height: 630px;

    @media (max-width: 767px) {
      padding-top: 150px;
    }
  }

  &__text {
    position: relative;
    max-width: 810px;
    font-size: 36px;
    line-height: 1.25;
    font-weight: bold;
    padding-bottom: 55px;
    margin: 0;
    margin-bottom: 44px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 91px;
      height: 8px;
      background-color: #f1f1f1;
    }

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__link-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__link-item {
    margin-right: 38px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1023px) {
      margin-right: 20px;
    }

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }

  &__btn-prev {
    margin-left: auto;
  }

  &__btn-next {
    @media (max-width: 1023px) {
      margin-right: auto;
    }
  }

  &__toggles {
    position: static;
    display: flex;
    list-style: none;
    padding: 0;
    margin-right: 40px;

    @media (max-width: 767px) {
      margin-right: 20px;
    }
  }

  &__toggle-item {
    width: 12px;
    height: 12px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    outline: none;
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active {
      background-color: #ffffff;
    }
  }

  &__toggle-item-color {
    width: 12px;
    height: 12px;
    border: 1px solid $color-lightblue;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    outline: none;
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active {
      background-color: $color-lightblue;
    }

    @media (max-width: 767px) {
      margin-right: 8px;
      width: 10px;
      height: 10px;
    }
  }
}
