.newsitem {
  padding-top: 75px;
}

.contaiter {
  div {
    span {
      a {
        text-decoration: underline;
      }
    }
  }
}

.newsitem__images {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 36px;
}

.newsitem__image {
  width: 100%;
  padding: 0;
  margin: 0;
}

.newsitem__image img {
  margin: 0 auto;
  width: 750px;
}

.newsitem__text {
  width: 690px;
  margin: 0 auto;
  padding-top: 60px;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2c2c2c;
  padding-bottom: 45px;
}

.newsitem__text p {
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
}

.newsitem__text a {
  text-decoration: underline;
}

.tinymce__content a {
  text-decoration: underline;
}

.newsitem__wrapper {
  width: 930px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: center;
  padding-bottom: 60px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    background-color: #e6e6e6;
    height: 2px;
    bottom: -2px;
  }
}

.coinvestors__wrapper {
  justify-content: flex-end;
  &::before {
    display: none;
  }
}

.newsitem__backlink {
  border-radius: 22.5px;
  background-color: #f1f1f1;
  color: #3e6bff;
  display: flex;
  align-items: center;
  padding-right: 19px;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

.newsitem__backlink svg {
  margin-left: 17px;
  margin-right: 2px;
}

.ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more{
  opacity: 0 !important;
}

.ya-share2__popup{
  background: white !important;
}

.newsitem__button {
  border-radius: 22.5px;
  background-color: #f1f1f1;
  color: #3e6bff;
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  padding-left: 18px;
  cursor: pointer;
  transition: 0.3s ease;
  height: 30px;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

.newsitem__button svg {
  margin-left: 6px;
  margin-right: 13px;
}



@media (max-width: $tablet-width) {
  .newsitem__wrapper {
    width: 100%;
    max-width: 930px;
  }
}

.ya-share2__item_more {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media (max-width: $mobile-width) {
  .newsitem__text {
    width: 100%;
    max-width: 690px;
    margin: 0 auto;
    padding-top: 15px;
    font-size: 14px;
    padding-bottom: 15px;
  }

  .newsitem__image {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .newsitem__wrapper {
    flex-direction: column;
    align-items: center;
    padding-bottom: 45px;
  }

  .newsitem__backlink {
    padding-right: 19px;
    margin-bottom: 30px;
    width: 250px;
  }

  .newsitem__button {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 18px;
  }
}
