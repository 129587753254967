.main-investors {
  padding: 0 0 76px;
  color: $color-lightblack;

  &__title {
    margin-bottom: 25px;
    padding-bottom: 59px;
  }

  &__subtext {
    max-width: 645px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.88;
    color: $color-lightblack;
    margin: 0;
    margin-bottom: 6px;
  }

  &__content-wrap {
    display: flex;
    width: 100%;

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }

  &__content {
    width: percentage(1080/1410);
    flex-shrink: 0;

    @media (max-width: 1023px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    font-family: $font-family--robotomono;
    margin-bottom: 60px;
  }

  &__main-icon-wrap {
    position: relative;
    width: 208px;
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(135deg, #c2185b, #304ffe 65%);
    margin: 0 auto;
    margin-bottom: 45px;

    @media (max-width: 767px) {
      width: 150px;
      height: 150px;
    }

    svg {
      width: 110px;
      height: 110px;

      @media (max-width: 767px) {
        width: 70px;
        height: 70px;
      }
    }
  }

  &__types {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    justify-content: space-around;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__types-svg {
    position: absolute;
    width: percentage(722/1080);
    height: 100px;
    top: -13px;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: -1;

    svg {
      width: 100%;
      height: 100px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__type {
    width: percentage(315/1080);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    svg {
      position: absolute;
      width: percentage(722/1080);
      height: 100px;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: -1;
    }

    @media (max-width: 767px) {
      flex-direction: row;
      // align-items: flex-end;
      width: 100%;
      flex-wrap: wrap;
      max-width: 550px;
      margin: 0 auto;
    }

    @media (max-width: 450px) {
      align-items: normal;
    }
  }

  &__icon-wrap {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 0 0 22px 5px #00000030;
    margin-bottom: 30px;
    flex-shrink: 0;

    .main-investors__icon {
      position: absolute;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
      width: 66px;
      height: 66px;
      top: 50%;
      z-index: 0;
      color: #8e8787;
  
      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
      }
    }

    @media (max-width: 767px) {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
  }

  &__tipe-title {
    font-family: $font-family--robotomono;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    min-height: 60px;
    padding: 0;
    margin: 0;
    color: $color-lightblack;
    margin: 0;

    @media (max-width: 767px) {
      margin-bottom: 0;
      width: 65%;
    }
  }

  &__info-list {
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      margin-left: 30px;
    }
  }

  &__info-item {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #000000;
    display: flex;
    align-items: baseline;

    &::before {
      content: "";
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #80a1ff;
      margin-right: 10px;
    }
  }

  &__form-wrap {
    width: percentage(330/1410);
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 30px 30px 89px;
    margin-top: -9px;
    background-image: linear-gradient(to bottom, #f5f5f5, #e0e0e0);

    @media (max-width: 1023px) {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__form {
    margin-bottom: 42px;
  }

  &__form-title {
    font-family: $font-family--robotomono;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    margin-bottom: 89px;
  }

  &__form-input {
    width: 100%;
    padding: 10px 20px;
    border-radius: 30px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71;
    margin-bottom: 18px;
    outline: none;

    &:last-of-type {
      margin-bottom: 30px;
    }

    &:focus {
      border: 1px solid #304ffe;
    }

    &::placeholder {
      font-weight: 300;
      color: #000000;
    }
  }

  &__form-btn {
    padding: 8px 20px;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.82;
    margin-bottom: 45px;
    background-color: $color-blue;
    color: #ffffff;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__info-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    margin: 0;
    color: #000000;
  }
}
