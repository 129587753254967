.accordion {
  display: flex;
  flex-direction: column;
  color: #2c2c2c;
  width: 690px;
  margin: 0 auto;
  transition: 0.3s ease;
  position: relative;

  &:first-child {
    padding-top: 46px;
  }

  &:last-child {
    padding-bottom: 180px;

    &::after {
      content: "";
      position: absolute;
      width: 690px;
      height: 2px;
      background-color: #e6e6e6;
      left: 0;
      bottom: 177px;
    }
  }

  &--coinvestors:last-child {
    padding-bottom: 120px;

    &::after {
      bottom: 118px;
    }
  }

  &--structure {
    padding-bottom: 0;

    &::after {
      content: "";
      position: absolute;
      width: 690px;
      height: 2px;
      background-color: #e6e6e6;
      left: 0;
      bottom: -1px;
    }
  }
}

.accordion__toggle {
  &--active button::before {
    background-image: url("../img/svg/button-close.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 48px;
    height: 48px;
    right: 8px;
    top: 49px;
  }
}

.accordion__button {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  padding: 0;
  width: 690px;
  position: relative;
  background-color: #ffffff;
  text-align: left;
  border: none;
  margin: 0 auto;
  padding-top: 59px;
  padding-bottom: 59px;
  padding-right: 70px;
  outline: none;
  border-top: solid 2px #e6e6e6;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
    color: $color-blue;
  }

  &:active {
    opacity: 0.6;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/svg/button-open.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    right: 12px;
    top: 53px;
  }
}

.accordion__panel {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  padding: 0;
  width: 690px;
  margin: 0 auto;
  padding-bottom: 45px;
  display: none;
  overflow: hidden;



  &--opened {
    display: block;
    &.accordion__panel-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  &__half {
    width: 48%;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
    .accordion__panel__half__link {
      padding-left: 0;
    }
    &__link {
      span {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #000;
        &:hover {
          color: #5974ff;
        }
      }
      img {
        margin-bottom: 15px;
        width: 140px;
        height: 100px;
        -o-object-fit: contain;
           object-fit: contain;
      }
    }
  }
}

.accordion__panel p {
  padding: 0;
  margin: 0;
  padding-bottom: 15px;
}

.accordion__panel--regulations ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 55px;
  padding-bottom: 9px;
}

.accordion__panel--regulations li {
  padding-bottom: 28px;

  &:last-child {
    padding-bottom: 0;
  }
}

.accordion__panel--regulations b {
  @include fontface(Montserrat, Montserrat, Montserrat-SemiBold, 600, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/svg/document.svg");
    background-repeat: no-repeat;
    width: 23px;
    height: 23px;
    left: -39px;
    top: -1px;
  }
}

.accordion__panel--regulations p:first-of-type {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  padding: 0;
  margin: 0;
  padding-bottom: 16px;
}

.accordion__panel div {
  display: flex;
}

.accordion__panel a {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #3e6bff;
  transition: 0.3s ease;
  &[aria-label="скачать документ"] {
    padding-left: 32px;
  }
  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

@media (max-width: $tablet-width) {
  .accordion {
    &:last-child {
      padding-bottom: 100px;

      &::after {
        width: 100%;
        max-width: 690px;
        left: 0;
        bottom: 95px;
      }
    }

    &--coinvestors {
      padding-bottom: 100px;

      &::after {
        bottom: 98px;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .accordion {
    width: 100%;
    max-width: 690px;

    &--structure {
      &::after {
        width: 100%;
        max-width: 690px;
      }
    }
  }

  .accordion:first-child {
    padding-top: 15px;
  }

  .accordion__button {
    font-size: 20px;
    width: 100%;
    max-width: 690px;
    padding-right: 80px;
    padding-bottom: 45px;
    padding-top: 45px;

    &::before {
      top: 38px;
    }

    &::after {
      width: 100%;
      max-width: 690px;
    }
  }

  .accordion__toggle--active button::before {
    top: 34px;
  }

  .accordion__panel {
    font-size: 14px;
    width: 100%;
    max-width: 690px;
  }

  .accordion__panel--regulations__item b {
    font-size: 14px;
  }

  .accordion__panel--regulations__item p {
    font-size: 14px;
  }

  .accordion__panel--regulations__download {
    font-size: 14px;
  }
}


.accordion__panel .grid__block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
  -moz-column-gap: 30px;
       column-gap: 30px;
  row-gap: 30px;
}

.grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  strong {
    font-size: 40px;
    font-weight: 900;
  }
  .grid__title {
    font-size: 20px;
    font-weight: 600;
  }
  .grid__subtitle {
    font-size: 18px;
    font-weight: 400;
  }
}