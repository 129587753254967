.portfolio {
  &__subtitle {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    font-family: $font-family--robotomono;
    margin: 0;
    margin-bottom: 30px;
  }

  &__main-icon-wrap {
    position: relative;
    width: 208px;
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(135deg, #c2185b, #304ffe 65%);
    margin: 0 auto;
    margin-bottom: 45px;

    @media (max-width: 767px) {
      width: 150px;
      height: 150px;
    }

    svg {
      width: 110px;
      height: 110px;

      @media (max-width: 767px) {
        width: 70px;
        height: 70px;
      }
    }
  }

  &__types {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    justify-content: space-around;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__types-svg {
    position: absolute;
    width: percentage(722/1080);
    height: 100px;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: -1;

    svg {
      width: 100%;
      height: 100px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__type {
    width: percentage(315/1080);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    svg {
      position: absolute;
      width: percentage(722/1080);
      height: 100px;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: -1;
    }

    @media (max-width: 767px) {
      flex-direction: row;
      // align-items: flex-end;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &__icon-wrap {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 0 0 22px 5px #00000030;
    margin-bottom: 30px;
    flex-shrink: 0;

    .portfolio__icon {
      position: absolute;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
      width: 66px;
      height: 66px;
      top: 50%;
      z-index: 0;
      color: #8e8787;

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
      }
    }

    // svg {
    //   top: 50%;
    //   z-index: 0;
    //   color: #8e8787;
    // }

    @media (max-width: 767px) {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
  }

  &__tipe-title {
    font-family: $font-family--robotomono;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    min-height: 60px;
    padding: 0;
    margin: 0;
    color: $color-lightblack;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      width: 65%;
    }
  }

  &__info-list {
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      margin-left: 30px;
    }
  }

  &__info-item {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #000000;
    display: flex;
    align-items: baseline;

    &::before {
      content: "";
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #80a1ff;
      margin-right: 10px;
    }
  }
}

.portfolio__subtitle--coinvestors {
  margin-bottom: 62px;
}

.portfolio__types--coinvestors {
  margin: 0 auto;
  max-width: 1043px;

  @media (max-width: 1023px) {
    width: 100%;
  }
}

.portfolio__types-svg--coinvestors {
  height: 123px;
}

.portfolio__type--coinvestors {
  padding-top: 19px;

  @media (max-width: 767px) {
    padding-top: 40px;
    max-width: 550px;
    margin: 0 auto;
    align-items: center;
  }

  @media (max-width: 450px) {
    align-items: normal;
  }
}

.portfolio__tipe-title--coinvestors {
  margin: 0;

  @media (max-width: 767px) {
    // margin-bottom: 30px;
  }

  // @media (max-width: 450px) {
  //   align-items: baseline;
  // }
}

