.main-subscribe {
  padding: 54px 0 44px;
  background: linear-gradient(110deg, #c2185b, #304ffe 50%);

  &__wrap {
    display: flex;
    // justify-content: center;
    width: 100%;
    max-width: 720px;
    // margin: 0 auto;
    margin-left: 33%;

    @media (max-width: 1200px) {
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__svg-wrap {
    width: percentage(80/720);
    margin-top: -11px;
    flex-shrink: 1;

    @media (max-width: 767px) {
      margin-bottom: 30px;
      width: auto;
    }
  }

  &__content {
    width: percentage(640/720);
    display: flex;
    flex-direction: column;
    padding-left: 60px;

    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  &__title {
    font-family: $font-family--robotomono;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 14px;
    line-height: 1.71;
    margin: 0;
    padding: 0;
    margin-bottom: 18px;
  }

  &__form {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__form-input {
    width: 330px;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 13px 0 13px 23px;
    border: 0;
    outline: none;
    border: 1px solid transparent;

    &:focus {
      border-color: #000000;
    }

    &::placeholder {
      font-weight: 300;
      color: #000000;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  &__form-btn {
    font-family: $font-family--monsterrat;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.88;
    border-radius: 30px;
    padding: 7px 40px;
    padding-right: 60px;
    margin-right: 17px;
    background-color: $color-lightblack;
    border: 0;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}