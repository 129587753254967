.main-about {
  color: #2c2c2c;
  display: flex;
  padding-bottom: 38px;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-bottom: 70px;
  }

  @media (max-width: 767px) {
    padding-bottom: 10px;
  }

  &__title {
    position: relative;
    top: 85px;
    margin-bottom: 37px;
    padding-bottom: 61px;

    @media (max-width: 1023px) {
      top: auto;
      padding-top: 50px;
    }

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  &__content-wrap {
    width: 50%;
    flex-shrink: 0;
    padding-right: 20px;
    padding-top: 100px;

    @media (max-width: 1023px) {
      width: 100%;
      padding-right: 0;
      padding-top: 0;
    }
  }

  &__subtitle {
    font-family: $font-family--robotomono;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    margin-bottom: 30px;

    &--second {
      margin-bottom: 25px;
    }

    &--ministry {
      margin-bottom: 21px;
    }
  }

  &__icon-edit {
    vertical-align: text-bottom;
    flex-shrink: 0;
  }

  &__description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.88;
    margin: 0;
    margin-bottom: 30px;

    &--icon {
      margin-bottom: 0;
      margin-left: 10px;

      a {
        text-decoration: underline;
      }
    }

    &--text {
      max-width: 570px;
    }

    &--target {
      margin-bottom: 0;
    }

    &--decree {
      margin-bottom: 62px;
    }
  }

  &__links-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 27px;
    margin-top: 40px;
    // width: max-content;

    &--second {
      margin-bottom: 35px;
    }

    &--column {
      flex-direction: column;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &__current-link {
    margin-bottom: 30px;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    &--col {
      margin-right: auto;
      margin-bottom: 30px;

      &:last-child {
        margin-right: auto;
      }
    }
  }

  &__graphics-wrap {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    flex-direction: column;
    padding-left: 15px;

    @media (max-width: 1023px) {
      width: 100%;
      // flex-direction: row;
      align-items: center;
      order: -1;
      margin-bottom: 50px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }

  &__structure-wrap {
    display: flex;
    width: 100%;
    position: relative;
    top: -51px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-self: baseline;
    }
  }

  &__services-wrap {
    padding-bottom: 45px;
    width: 100%;
    position: relative;
    top: -14px;

    @media (max-width: 767px) {
      width: 100%;
      padding-bottom: 0;
    }
  }

  &__structure-list {
    width: percentage(220/690);
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__structure-item {
    flex-shrink: 2;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #000000;
    margin: 28px 0;

    @media (max-width: 767px) {
      margin: 0 0 25px;
    }
  }

  &__icon-structure {
    flex-shrink: 1;
    width: percentage(700/705);
    height: 630px;

    @media (max-width: 1023px) {
      width: 700px;
    }

    @media (max-width: 767px) {
      width: percentage(700/705);
      height: 82vw;
    }

    @media (max-width: 500px) {
      width: 100%;
      // height: auto;
      flex-direction: column;
      height: 325px;
    }
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__service-title {
    font-family: $font-family--robotomono;
    font-size: 19px;
    font-weight: 700;
    line-height: 1.25;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
  }

  &__year-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 480px;

    @media (max-width: 500px) {
      width: 100%;
      justify-content: space-around;
    }
  }

  &__year {
    font-family: $font-family--robotomono;
    font-size: 24px;
    line-height: 1.67;
    font-weight: 700;

    &--ml1 {
      margin-left: 15px;

      @media (max-width: 500px) {
        margin-left: 0;
      }
    }

    &--ml2 {
      margin-left: 110px;

      @media (max-width: 500px) {
        margin-left: 0;
      }
    }

    &--ml3 {
      margin-left: 140px;

      @media (max-width: 500px) {
        margin-left: 0;
      }
    }
  }

  &__icon-services {
    width: percentage(500/705);
    height: 235px;

    @media (max-width: 1023px) {
      width: 505px;
    }

    @media (max-width: 570px) {
      width: 100%;
    }
  }
}
