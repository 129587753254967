.description-block {
  background-image: linear-gradient(170deg, #304ffe 10%, #283593 92%);
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 270px;
  padding-bottom: 45px;

  &--coinvestors {
    margin-bottom: 74px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 76px;
    height: 8px;
    background-color: #ffffff;
    bottom: 72px;
    left: calc(50% - 42px);
  }

  &--newsitem {
    background-image: none;
    background-color: #f1f1f1;
  }

  &--newsitem::before {
    background-color: #3e6bff;
  }
}

.description-block__no-white-box::before {
  content: none;
}

.description-block__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.description-block__link {
  @include fontface(Monsterrat, Monsterrat, Montserrat-Regular, 400, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: center;
  color: #3e6bff;
  padding-left: 19px;
  padding-right: 19px;
  border-radius: 22.5px;
  background-color: #ffffff;
  margin-bottom: 26px;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

.description-block__container h1 {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  width: 930px;
  padding: 0;
  margin: 0;
  padding-bottom: 66px;
}

.description-block__container--newslist h1 {
  padding-bottom: 50px;
}

.description-block--newsitem h1 {
  color: #2c2c2c;
}

.description-block__backlink {
  left: calc(50% - 558px);
  top: 75px;

  &--newsitem {
    top: 63px;
  }

  @media (max-width: 1170px) {
    left: calc(50% - 490px);
  }
}

.description-block__container p {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
  width: 690px;
  padding: 0;
  margin: 0;
}

.description-block--newsitem p {
  @include fontface(Montserrat, Montserrat, Montserrat-Light, 300, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2c2c2c;
  width: 579px;
  padding: 0;
  margin: 0;
  padding-bottom: 58px;
}

.description-block__container--coinvestors p {
  text-align: center;
  width: 650px;
  padding-bottom: 86.5px;
}

.description-block__container--coinvestors h1 {
  padding-bottom: 52px;
}

.description-block__button {
  @include fontface(Monsterrat, Monsterrat, Montserrat-Regular, 400, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
  width: 160px;
  background-image: linear-gradient(119deg, #c2185b, #304ffe 57%);
  border-radius: 22.5px;
  padding-top: 7px;
  padding-bottom: 8px;
  border: none;
  position: relative;
  margin-bottom: 75.5px;
  margin-top: 26px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.description-block__wrap {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 74px;
}

.description-block__wrap p {
  padding: 0;
  margin: 0;
  padding-right: 10px;
}

.description-block--newsitem span {
  color: #3e6bff;
  padding-left: 7px;
}

@media (max-width: $tablet-width) {
  .description-block {
    min-height: 320px;
  }

  .description-block__container {
    max-width: 690px;
  }

  .description-block__container h1 {
    width: 90%;
    max-width: 690px;
    padding-bottom: 30px;
  }

  .description-block__backlink {
    left: 0;
  }
}

@media (max-width: $mobile-width) {
  .description-block {
    min-height: 385px;
  }

  .description-block__container {
    max-width: 690px;
  }

  .description-block__container h1 {
    font-size: 28px;

    @media (max-width: 414px) {
      font-size: 26px;
    }
  }

  .description-block__container--newsitem h1 {
    @media (max-width: 414px) {
      font-size: 24px;
    }
  }

  .description-block__container h1,
  .description-block__container p {
    width: 90%;
    max-width: 690px;
  }

  .description-block__container p {
    text-align: center;
  }

  .description-block__wrap {
    width: 90%;
    max-width: 576px;
    overflow-x: scroll;
    margin: 0 auto;
    padding-top: 20px;
  }

  .description-block__backlink {
    left: 10px;
  }

  .description-block__backlink--newsitem {
    left: 0;
  }
}
