.competition {
  &__head-wrap {
    background-color: #f1f1f1;
    padding: 61px 0 70px;
  }

  &__head {
    text-align: center;
  }

  &__title {
    position: relative;
    max-width: 930px;
    font-family: $font-family--robotomono;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.25;
    color: #2c2c2c;
    margin: 0 auto;
    padding-bottom: 38px;

    @media (max-width: 767px) {
      font-size: 22px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 76px;
      height: 8px;
      background-color: $color-lightblue;
      transform: translateX(-50%);
    }
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 161px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 100px;
    }

    @media (max-width: 767px) {
      padding-bottom: 50px;
    }
  }

  &__col {
    width: percentage(690/1410);
    margin-bottom: 30px;

    @media (max-width: 1023px) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__description {
    max-width: 690px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.88;
    color: #2c2c2c;
    padding: 81px 0 55px;
    margin: 0 auto;

    @media (max-width: 767px) {
      padding: 55px 0 40px;
      font-size: 14px;
    }
  }
}
