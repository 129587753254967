.footer {
  min-height: 100px;
  padding-top: 60px;
  padding-bottom: 92px;

  background-color: $color-blue;
  background-image: url("../img/bg/footer@1x.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    background-image: url("../img/bg/footer@2x.jpg");
  }
}

@media (max-width: $tablet-width) {
  .footer {
    background-image: none;
  }
}
