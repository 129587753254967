.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigation__wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #ffffff;
  position: relative;
  width: 17%;

  &--small {
    width: 11.8%;
  }

  &--wide {
    width: 23.4%;
  }

  &--medium {
    width: 13.8%;

    &::before {
      display: none;
    }

    @media (max-width: 1200px) {
      width: 18%;
    }
  }
}

.navigation__header {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0;
  margin: 0;
  padding-bottom: 58px;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    transition: 0.3s ease;
  }

  &:active {
    opacity: 0.6;
    transition: 0.3s ease;
  }
}

.navigation__header a {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #ffffff;
}

.navigation__list {
  @include fontface(Monsterrat, Monsterrat, Montserrat-Regular, 400, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  letter-spacing: normal;
  color: #ffffff;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;

  &--about {
    padding-right: 30px;
  }

  &--grant {
    padding-right: 30px;
  }

  &--contacts {
    padding-top: 3px;
  }

  &::before {
    content: "";
    position: absolute;
    background: #f1f1f1;
    width: 45px;
    height: 4px;
    top: -44px;
  }
}

.navigation__link p {
  padding: 0;
  margin: 0;
  margin-bottom: -5px;
}

.navigation__link > svg {
  margin-right: 11px;
  margin-top: 3px;
}

.navigation__link--address {
  display: flex;
}

.navigation__item {
  position: relative;

  &--contacts {
    padding-bottom: 13px;
    padding-left: 4px;
  }

  &--social {
    padding-top: 20px;
  }

  &--address {
    display: flex;
    flex-direction: row;
  }
}

.navigation__link {
  @include fontface(Monsterrat, Monsterrat, Montserrat-Regular, 400, normal);
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: #ffffff;
  transition: 0.3s ease;

  &--social {
    @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
    font-family: "RobotoMono", "Arial", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
  }

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

@media (max-width: $tablet-width) {
  .navigation {
    flex-direction: column;
    margin: 0 auto;
  }

  .navigation__wrapper {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .navigation__list {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 0;
  }

  .navigation__item {
    width: 50%;
  }

  .navigation__header {
    padding-bottom: 58px;

    &--news {
      width: 17%;
    }

    &--coinvestors {
      width: 13.8%;
    }
  }
}

@media (max-width: $mobile-width) {
  .navigation__wrapper {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .navigation__list {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 0;
  }

  .navigation__item {
    width: 100%;
  }

  .navigation__header {
    padding-bottom: 58px;

    &--news {
      width: 17%;
    }

    &--coinvestors {
      width: 13.8%;
    }
  }
}
