// Colors
//---------------------------------

// Default

$color-default-black:             #000000;
$color-default-white:             #ffffff;
$color-transparent:               rgba(0, 0, 0, 0);

// Base

$color-black:                     #0e0e0e;
$color-lightblack:                #2c2c2c;
$color-white:                     #f2f2f2;
$color-gray:                      #f1f1f1;
$color-gray2:                     #d8d8d8;
$color-blue:                      #304ffe;
$color-lightblue:                 #3e6bff;
$color-lightblue2:                #8c9eff;

// Gradient

$black-to-right:                    linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);

// Typography
//---------------------------------

$font-family--monsterrat:                     "Montserrat", "Arial", sans-serif;
$font-family--robotomono:                     "RobotoMono", "Arial", sans-serif;
$font-family--roboto:                         "Roboto", "Arial", sans-serif;

// Animation
//---------------------------------

$default-transition-settings:       0.3s ease;
$modal-transition-settings:         0.6s cubic-bezier(0.55, 0, 0.1, 1);

// Viewports
//---------------------------------

$viewport--sm:                      320px;
$viewport--md:                      768px;
$viewport--lg:                      1024px;
$viewport--xl:                      1440px;
$viewport--xxl:                     1920px;

// Retina
//---------------------------------

$retina-dpi:                        144dpi;
$retina-dppx:                       1.5dppx;

// Screen-width
//---------------------------------

$mobile-width: 767px;
$tablet-width: 1023px;
$desktop-width: 1024px;
$mobile-width-only: 767px;
