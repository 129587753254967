.conditions {
  margin-bottom: 182px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 690px;
    height: 2px;
    background-color: #e6e6e6;
    left: 18px;
    bottom: -3px;
  }
}

@media (max-width: $mobile-width) {
  .conditions {
    &::after {
      width: 90%;
      max-width: 690px;
      left: 32px;
    }
  }
}
