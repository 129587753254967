.btn-wrap {
  display: flex;
  align-items: center;
  width: max-content;
  margin-left: auto;

  &--no-slider {
    display: none;
  }

  &--main {
    position: absolute;
    bottom: 25px;
    right: 78px;
    z-index: 10;

    @media (max-width: 1023px) {
      right: 50%;
      transform: translate3d(50%, 0, 0);
    }
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
  }

  &__btn {
    position: relative;
    display: block;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: #ffffff;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }

    &:first-child {
      margin-right: 40px;

      @media (max-width: 767px) {
        margin-right: 20px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
    }

    &--color {
      color: $color-lightblue;
    }
  }
}
