.block-grant {
  color: #ffffff;
  padding: 32px 30px 20px;
  box-shadow: 0 0 90px 10px #00000030;
  background-image: linear-gradient(174deg, #304ffe 4%, #283593 97%);
  margin-bottom: 22px;
  
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--grant {
    width: 690px;
    box-shadow: none;
    background-image: none;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 78px;
    margin: 0;

    @media (max-width: 767px) {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  &__subtitle {
    font-family: $font-family--robotomono;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    min-height: 90px;
    margin: 0 auto;
    margin-bottom: 23px;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    margin-bottom: 30px;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  &__item {
    width: percentage(200/630);
    padding-right: percentage(23/630);
    margin-right: percentage(23/630);
    border-right: solid 1px $color-lightblue2;
    flex-shrink: 0;
    text-align: center;
    margin-bottom: 30px;

    &:nth-child(3n) {
      width: percentage(180/630);
      padding-right: 0;
      margin-right: 0;
      border-right: none;

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    @media (max-width: 500px) {
      display: flex;
      align-items: center;
      border-right: none;
      width: 100%;
      margin: 0;
      margin-bottom: 30px;
      padding-right: 0;
      text-align: left;
    }
  }

  &__data-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    box-shadow: 0 0 22px 5px #00000030;
    background-color: #233591;
    border-radius: 50%;
    margin-bottom: 27px;
    flex-shrink: 0;

    @media (max-width: 500px) {
      width: 90px;
      padding-top: 90px;
      margin: 0 15px 0 0;
    }

    @media (max-width: 450px) {
      margin: 0 15px 0 0;
    }
  }

  &__info-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    transform: translate3d(-50%, -50%, 0);
    font-family: $font-family--robotomono;
    font-size: 1vw;
    line-height: 32px;
    font-weight: 700;
    text-align: left;

    span {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
    }

    @media (min-width: 1530px) {
      font-size: 19px;
    }

    @media (max-width: 1023px) {
      font-size: calc(10px + 1vw);
      line-height: 20px;
    }

    @media (max-width: 767px) {
      font-size: calc(8px + 1vw);
    }

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  &__item-text {
    font-size: 16px;
    line-height: 1.88;
    min-height: 60px;

    @media (max-width: 767px) {
      font-size: 12px;
    }

    @media (max-width: 500px) {
      min-height: auto;
    }
  }

  &__description {
    margin: 0;
    font-size: 16px;
    line-height: 1.88;
    margin-bottom: 42px;

    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  &__btn-wrap {
    display: flex;
    // justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 20px;

    a.block-grant__btn-footer {
      padding: 5px 28px;
      font-weight: 500;
      margin-right: 25px;

      @media (max-width: 767px) {
        padding: 5px 18px;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__btn-footer {
    margin-bottom: 20px;
    align-self: baseline;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  &__item-icon {
    width: 45px;
    height: 45px;
    margin-bottom: 20px;

    @media (max-width: 500px) {
      margin-right: 5px;
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }
}

.block-grant__description--grant {
  display: none;
}

.block-grant__btn-wrap--grant {
  display: none;
}

.block-grant__item--grant {
  margin-bottom: 0;

  @media (max-width: $mobile-width) {
    margin-bottom: 30px;
  }
}

.block-grant__data-wrap--grant {
  margin-bottom: 27px;
  padding-top: 100%;

  @media (max-width: 500px) {
    padding-top: 90px;
  }

  @media (max-width: 767px) {
    // padding-top: 95%;
    margin-bottom: 0;
  }
}

.block-grant__item-icon--grant {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

.block-grant__list--grant {
  @media (max-width: 767px) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

