.main-partners {
  padding-bottom: 168px;
  padding-top: 53px;

  @media (max-width: 1023px) {
    padding-bottom: 100px;
  }

  @media (max-width: 767px) {
    padding-bottom: 50px;
  }

  &__title {
    margin-bottom: 77px;
    padding-bottom: 59px;
  }

  &__item {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 70px;

    @media (max-width: 1023px) {
      margin-right: 60px;
    }
  }

  &__btn-prev {
    margin-left: auto;
  }

  &__btn-next {
    @media (max-width: 1023px) {
      margin-right: auto;
    }
  }
}
