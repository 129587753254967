.main-activity {
  padding: 30px 0 88px;
  background: url("../img/bg/bg-light-grey-copy.png") no-repeat;
  background-size: 100% 620px;
  color: #2c2c2c;

  @media (max-width: 767px) {
    padding: 30px 0 50px;
  }

  &__head {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 50px;
    }
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }

  &__title {
    padding-bottom: 47px;
    margin-bottom: 63px;
    color: #ffffff;

    &::before {
      background-color: #ffffff;
    }
  }

  &__toggles {
    display: flex;
    align-items: baseline;
    background-color: #ffffff;
    align-self: baseline;
    border-radius: 25px;
    padding: 4px;
    flex-shrink: 0;
    margin-top: 13px;

    @media (max-width: 767px) {
      padding: 2px;
    }
  }

  &__btn {
    padding: 3px 23px;
    background-image: none;
    color: $color-lightblue;
    font-size: 14px;
    line-height: 2.14;
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    &:first-child {
      margin-right: 20px;

      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }

    &--active {
      background-color: #c51162;
      color: #ffffff;

      &:hover {
        opacity: 1;
        background-color: #b82064b8;
      }
    }

    @media (max-width: 767px) {
      font-size: 10px;
      line-height: 1.5;
    }

    @media (max-width: 400px) {
      padding: 5px 12px;
    }
  }

  &__content {
    display: none;
    width: 100%;
    justify-content: space-between;

    &--is-show {
      display: flex;
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__left-col {
    padding: 35px 50px;
    padding-bottom: 58px;
    width: percentage(690/1410);
    background-color: #f2f2f2;
    box-shadow: 0 0 22px 5px #00000030;

    @media (max-width: 1023px) {
      width: 100%;
      max-width: 690px;
      margin-bottom: 40px;
    }

    @media (max-width: 767px) {
      padding: 30px 15px;
    }
  }

  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    margin-bottom: 36px;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__subtitle {
    font-family: $font-family--robotomono;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    max-width: 490px;
    min-height: 60px;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  &__description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.88;
    margin: 0;
    margin-bottom: 40px;
    text-align: center;
  }

  &__item-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #000000;
    max-width: 210px;

    @media (max-width: 767px) {
      max-width: none;
    }
  }

  &__icon {
    margin-bottom: 25px;
    color: $color-lightblue;
  }

  &__btn-wrap {
    display: flex;
    justify-content: space-evenly;
    padding-top: 14px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__btn-footer {
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}
