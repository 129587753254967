.container {
  width: 100%;
  max-width: 1530px;
  margin: 0 auto;
  padding: 0 60px;

  @include viewport--lg {
    padding: 0 32px;
  }

  @include viewport--md {
    padding: 0 24px;
  }

  @include viewport--sm {
    padding: 0 16px;
  }

  &--no-padding {
    @include viewport--sm {
      padding: 0;
    }
  }
}
