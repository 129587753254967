.main-winners {
  color: #2c2c2c;
  padding-bottom: 70px;
  padding-top: 53px;

  &__head {
    display: flex;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__title {
    margin-right: 86px;
    max-width: none;
    padding-bottom: 54px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__head-link {
    align-self: baseline;
    flex-shrink: 0;
    margin-top: 10px;
  }

  &__content {
    display: flex;
    width: 100%;
  }

  &__icon-edit {
    vertical-align: text-bottom;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    width: percentage(450/1410);
    display: flex;
    flex-direction: column;
    margin-right: percentage(30/1410);
    margin-bottom: 30px;

    &:nth-child(3n) {
      margin-right: 0;

      @media (max-width: 767px) {
        margin-right: 5%;
      }

      @media (max-width: 650px) {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @media (max-width: 767px) {
        margin-right: 0;
      }
    }

    @media (max-width: 767px) {
      width: 45%;
      margin-right: 10%;
      margin-bottom: 40px;
    }

    @media (max-width: 650px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;
    }

    &.main-winners__item--form {
      @media (max-width: 767px) {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__image {
    min-height: 210px;
    object-fit: cover;
    margin-bottom: 28px;
  }

  &__item-title {
    margin: 0;
    padding: 0;
    font-family: $font-family--robotomono;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 35px;
  }

  &__item-data {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #000000;
    margin-bottom: 10px;

    &--last {
      margin-bottom: 3px;
    }

    &--text {
      margin-bottom: 69px;
    }
  }

  &__cost {
    font-family: $font-family--robotomono;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    margin-bottom: 24px;
  }

  &__cost-number {
    font-size: 60px;
    line-height: 70px;
    color: $color-lightblue;
  }

  &__monitiring-form {
    width: percentage(450/1410);
  }

  &__item-head {
    min-height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-bottom: 30px;
    padding: 0 30px;
    background-image: linear-gradient(133deg, #8c9eff 0%, #304ffe 65%);

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @media (max-width: 850px) {
      padding: 0 15px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
      flex-wrap: nowrap;
    }
  }

  &__item-description {
    font-family: $font-family--robotomono;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    margin: 0;
  }

  &__form-wrap {
    background-color: #f1f1f1;
    padding: 60px 30px 30px;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      padding: 60px 30px 50px;
    }

    @media (max-width: 850px) {
      padding: 60px 15px 50px;
    }

    @media (max-width: 767px) {
      padding: 60px 20px 50px;
      flex-wrap: nowrap;
    }
  }

  &__form-head {
    display: flex;
    align-items: center;
    margin-bottom: 53px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__form-title {
    font-family: $font-family--robotomono;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    color: #2c2c2c;
    margin: 0;
    padding-top: 9px;
  }

  &__item-icon {
    margin-right: 30px;
    flex-shrink: 0;
    width: 88px;
    height: 88px;

    @media (max-width: 767px) {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__input {
    padding: 10px 30px;
    border-radius: 30px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71;
    margin-right: 19px;
    margin-bottom: 15px;
    outline: none;

    &:focus {
      border: 1px solid #304ffe;
    }

    &::placeholder {
      font-weight: 300;
      color: #000000;
    }

    @media (max-width: 800px) {
      max-width: 205px;
    }

    @media (max-width: 767px) {
      max-width: none;
    }
  }

  &__submit {
    padding: 8px 37px;
    background-image: none;
    background-color: #c51162;
    margin-bottom: 15px;
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      background-color: #b82064b8;
    }
  }
}
