.meeting {
  width: 810px;
  padding-top: 117px;
  padding-bottom: 45px;
  padding-left: 30px;
  padding-right: 30px;
}

.meeting__wrap {
  background-image: linear-gradient(to bottom, #f5f5f5, #e0e0e0);
  background-color: #f5f5f5;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 89px;
}

.meeting h3 {
  @include fontface(RobotoMono, RobotoMono, RobotoMono-Bold, 700, normal);
  font-family: "RobotoMono", "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #2c2c2c;
  padding: 0;
  margin: 0;
  padding-bottom: 44px;
}

.meeting__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.meeting__wrapper p {
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  padding: 0;
  margin: 0;
  padding-right: 11px;
  padding-top: 5px;
  width: 330px;
}

@media (max-width: $tablet-width) {
  .meeting {
    width: 100%;
    max-width: 870px;
  }

  .meeting__wrapper p {
    width: 44.2%;
  }
}

@media (max-width: $mobile-width) {
  .meeting {
    padding-top: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .meeting__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .meeting__wrap {
    padding-bottom: 50px;
  }

  .meeting__wrapper p {
    width: 100%;
    max-width: 330px;
  }
}
